* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  padding: 2em;
  font: 24px/1.5 Arial, Helvetica, sans-serif;
  background-color: #fff;
  color: #ccc;
  text-align: center;
}

.pre {
  white-space: pre-wrap;
  font-family: monospace;
}