* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: #ccc;
  text-align: center;
  background-color: #fff;
  padding: 2em;
  font: 24px / 1.5 Arial, Helvetica, sans-serif;
}

.pre {
  white-space: pre-wrap;
  font-family: monospace;
}

/*# sourceMappingURL=index.cbc4be4e.css.map */
